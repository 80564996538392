import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Suspense, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "src/redux/store";
import Fallback from "src/shared/components/common/Fallback";
import theme from "src/shared/styles/theme";
import "./App.css";
import { FirebaseInitializer } from "./firebase";
import AppRouter from "./router/AppRouter";
import ScrollToTop from "./shared/components/common/ScrollToTop";
// import { CookiesProvider } from "react-cookie";

const App = (): JSX.Element => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false, refetchOnMount: false },
    },
  });

  const loadGoogleMaps = (apiKey: string) => {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.onload = () => console.log("Google Maps API Loaded");
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    loadGoogleMaps(process.env.REACT_APP_GOOGLE_API!);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        {/* <CookiesProvider defaultSetOptions={{ path: "/" }}> */}
        <Provider store={store}>
          <Suspense fallback={<Fallback />}>
            <FirebaseInitializer />
            <BrowserRouter>
              <ScrollToTop />
              <AppRouter />
            </BrowserRouter>
          </Suspense>
        </Provider>
        {/* </CookiesProvider> */}
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
